$(document).ready(function() {
	console.log("hello 👋");
});


$(".js-nav-toggle").click(function () {
	$(".js-nav-toggle").toggleClass('nav-toggle--active');
	$(".nav").toggleClass('nav--active');
});


if ($(window).width() > 850) {
	if ($(".alternating--align-top")[0]){
		$(".alternating--align-top").each(function(){
			var headingHeight = $(this).find(".alternating__heading").outerHeight();
			var marginHeight = headingHeight - 6 + "px"
			console.log(headingHeight)
			console.log(marginHeight)
			$(this).find(".alternating__text--two").css('margin-top', marginHeight);
		});
	}
}




// $(window).scroll(function() {});

// $(window).resize(function() {});
